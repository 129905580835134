import React from 'react';
import { isHauptadmin } from '../../lib/globalData';
import { useTranslate, 
  ListBase, Create, Edit, DatagridConfigurable, SimpleForm, TextField, CreateButton, 
  TextInput, SelectInput,
  Pagination, FilterForm } from 'react-admin';
import CloseToolbar from '../../lib/CloseToolbar';
import ConditionalEditButton from '../../fields/ConditionalEditButton';
import { SelectColumnsButton } from '../../lib/SelectColumnsButton.tsx';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';

import './agent.css'

const AgentEMailChoices = [
  { id: '0', name: 'glob.agentEmailType.guest' },
  { id: '1', name: 'glob.agentEmailType.guest_agent' },
  { id: '2', name: 'glob.agentEmailType.agent' },
]

const agentFilter = [
  <TextInput source="q" alwaysOn />
];

const ListToolbar = () => {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
          <FilterForm filters={agentFilter} />
          <Stack alignItems="flex-start">
              <SelectColumnsButton />
          </Stack>
          <Stack alignItems="flex-start">
              <CreateButton />
          </Stack>
      </Stack>
    </>
  )
}

const ListForm = (props) => {
  const {classes, ...rest} = props;

  return (
    <ListBase {...rest} title="bw.set.agent.title" sort={{ field: 'firma', order: 'ASC' }} perPage={25} >
      <ListToolbar />
      <DatagridConfigurable style={{ width: 'auto' }} bulkActionButtons={false}
        omit={['kontakt','strasse','land','tel','fax','email','emailtype','bank','postkonto','bankclearing','kontonummer','mwst','id']} >
        <TextField source="firma" />
        <TextField source="code" />
        <TextField source="plz_ort" />
        <TextField source="kontakt" />
        <TextField source="strasse" />
        <TextField source="land" />
        <TextField source="tel" />
        <TextField source="fax" />
        <TextField source="email" />
        <TextField source="emailtype" />
        <TextField source="bank" />
        <TextField source="postkonto" />
        <TextField source="bankclearing" />
        <TextField source="kontonummer" />
        <TextField source="mwst" />
        <ConditionalEditButton source="allow_edit" label=""  />
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
      </DatagridConfigurable>
      <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} />
    </ListBase>
  )
}


const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const translate = useTranslate();

  const FormValidation = (data) => {
    const errors = {};

    if(data.firma && data.firma.length > 50){
      errors.firma = [translate('bw.set.agent.error.maxlength', {max:'50'})];
    }
    if(data.code && data.code.length > 12){
      errors.code = [translate('bw.set.agent.error.maxlength', {max:'12'})];
    }
    const regexp = new RegExp('[^a-zA-Z0-9_]');
    if(data.code && regexp.test(data.code)){
      errors.code = [translate('bw.set.agent.error.regex')];
    }
    if(data.kontakt && data.kontakt.length > 80){
      errors.kontakt = [translate('bw.set.agent.error.maxlength', {max:'80'})];
    }
    if(data.strasse && data.strasse.length > 50){
      errors.strasse = [translate('bw.set.agent.error.maxlength', {max:'50'})];
    }
    if(data.land && data.land.length > 20){
      errors.land = [translate('bw.set.agent.error.maxlength', {max:'20'})];
    }
    if(data.tel && data.tel.length > 15){
      errors.tel = [translate('bw.set.agent.error.maxlength', {max:'15'})];
    }
    if(data.fax && data.fax.length > 15){
      errors.fax = [translate('bw.set.agent.error.maxlength', {max:'15'})];
    }
    if(data.email && data.email.length > 128){
      errors.email = [translate('bw.set.agent.error.maxlength', {max:'128'})];
    }
    if(data.bank && data.bank.length > 30){
      errors.bank = [translate('bw.set.agent.error.maxlength', {max:'30'})];
    }
    if(data.postkonto && data.postkonto.length > 30){
      errors.postkonto = [translate('bw.set.agent.error.maxlength', {max:'30'})];
    }
    if(data.bankclearing && data.bankclearing.length > 30){
      errors.bankclearing = [translate('bw.set.agent.error.maxlength', {max:'30'})];
    }
    if(data.kontonummer && data.kontonummer.length > 30){
      errors.kontonummer = [translate('bw.set.agent.error.maxlength', {max:'30'})];
    }
    if(data.mwst && data.mwst.length > 20){
      errors.mwst = [translate('bw.set.agent.error.maxlength', {max:'20'})];
    }
    return errors;
  }

  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props} validate={FormValidation}>
      <Grid container style={{width:'100%'}}>
        <Grid item xs={12} md={4}>
          <TextInput source="firma" label="bw.set.agent.firma" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="code" label="bw.set.agent.code" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="kontakt" label="bw.set.agent.kontakt" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="strasse" label="bw.set.agent.strasse" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="plz_ort" label="bw.set.agent.plz_ort" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="land" label="bw.set.agent.land" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="email" label="bw.set.agent.email" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="tel" label="bw.set.agent.tel" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="fax" label="bw.set.agent.fax" />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectInput source="emailtype" label="bw.set.agent.emailtype" choices={AgentEMailChoices} defaultValue={0}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="bank" label="bw.set.agent.bank" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="postkonto" label="bw.set.agent.postkonto" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="bankclearing" label="bw.set.agent.bankclearing" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="kontonummer" label="bw.set.agent.kontonummer" />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextInput source="mwst" label="bw.set.agent.mwst" />
        </Grid>
      </Grid>
    </SimpleForm>
  )
};

const Agentcontract =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),

  create: ({ classes, ...props }) => (
    <Create {...props} title="bw.set.agentcontract.titleCreate" redirect="list">
      <CEForm {...props} classes={classes} />
    </Create>
  ),

  edit: ({ classes, ...props }) => (
    <Edit {...props} title="bw.set.agentcontract.titleEdit" mutationMode="pessimistic">
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}
export default Agentcontract
