import React, { useState, useEffect } from 'react';
import dataProvider from '../../lib/dataProvider';
import { getInfoValue} from '../../lib/globalData';
import { useTranslate, Confirm } from 'react-admin';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Drawer from '@mui/material/Drawer';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import { ChromePicker } from 'react-color';
import SettingsIcon from '@mui/icons-material/Settings';
import ContentSave from '@mui/icons-material/Save';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CloseIcon from '@mui/icons-material/Close';

const Settings = (props) => {
  const translate = useTranslate();
  const { isOpen, show } = props;
  const [settings, setSettings] = useState(props.settings);
  
  useEffect(() => {
    setSettings(props.settings);
  }, [props.settings]);
  
  if (!settings) {
    return null;
  }

  const update = (name, pos) => event => {
    var v = false;
    if (name==='skipempty') {
      v = event.target.checked;
    }
    else if (name==='colors') {
      v = event.hex.substr(1);
    }
    else {
      v = event.target.value;
    }
    if (typeof pos!== 'undefined') {
      setSettings((settings) => {
        var arr = settings[name];
        arr.splice(pos, 1, v);
        return {...settings, [name]: arr }});
    }
    else {
      setSettings({...settings, [name]: v });
    }
  };    

  const save = event => {
    dataProvider.create('calYield', { data: settings })
      .then(res  => {
        props.saved(settings)
      })
      .catch(response => {
        window.location.hash = '/login';
      });
  }
  
  var limits = [];
  for(var i=0;i<4;i++) (
    limits.push(<Limit pos={i} key={i} color={settings.colors[i]} value={settings.limits[i-1]} update={update} />)
  )     
  return (
    <Drawer anchor="right" open={isOpen} onClose={show(false)}>
      <Card>
        <CardContent>
          <h3>{translate('bw.cal.yield.limits')}</h3>
          {limits}
          <TextField label={translate('bw.cal.yield.days')} onChange={update('lines')} value={settings.lines} type="number" 
          InputLabelProps={{ shrink: true, }} margin="normal"/><br />
          <FormControlLabel label={translate('bw.cal.yield.empty')} control={
              <Switch checked={settings.skipempty} onChange={update('skipempty')} name="checkedB" color="primary" />
            }
          />
          <h3>{translate('bw.cal.yield.groups')}</h3>
          <textarea value={settings.groups} onChange={update('groups')}  />
          <br/><br/><Button variant="contained" color="primary" value="-" onClick={save}>{translate('bw.cal.yield.save')}</Button>
        </CardContent>
      </Card>
    </Drawer>
  )  
};

const Limit = (props) => {
  const [showpicker, setShowpicker] = useState();

  const handleClick = () => {
    setShowpicker(!showpicker)
  };

  const handleClose = () => {                   
    setShowpicker(false)
  };
  const { pos, color, value, update } = props;
  return (
    <div>
      <div style={{position:'relative', top:'5px',width:'2em',height:'20px',marginRight:'10px', display:'inline-block', backgroundColor:'#'+color}} onClick={handleClick} />
      <input name={'limit'+pos} value={pos>0?value:0} type="number" style={{width:'3em', margin:0, textAlign:'right', }} onChange={update('limits', pos-1)} disabled={pos>0?false:true} />
      %
      { showpicker ? <div style={{position: 'absolute',zIndex: '2',} }>
          <div style={{position: 'fixed',top: '0px',right: '0px',bottom: '0px',left: '0px',}} onClick={ handleClose }/>
          <ChromePicker color={ '#'+color } onChange={ update('colors', pos) } disableAlpha={true} />
        </div> : null }
    </div>
  )  
};

const Yield = (props) => {
  const translate = useTranslate();
  const [week, setWeek] = useState(1);
  const [data, setData] = useState({});
  const [change, setChange] = useState({});
  const [detPreise, setDetPreise] = useState(0);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [lastStep, setLastStep] = useState(0);

  const ApplyChanges = (pks) => {
    for (var k in change) {
      var s = k.split('.');
      pks[s[1]].mod[s[3]] = change[k];
    }
  }
  
  useEffect(() => {
    if (week < -10000) {
      setWeek(week+20000) 
    }
    dataProvider.getOne('calYield', { id: week })
      .then(res  => {
        setData(res.data);
      })
  }, [week]);

  const handleButton = (step) =>(ev) => {
    if (Object.keys(change).length > 0) {
      setLastStep(step);
      setConfirmOpen(true);
    }
    else {
      setWeek(week + step);
    }
  }
  const handleDialogClose = () => setConfirmOpen(false);

  const handleConfirm = () => {
    setChange({});
    setWeek(week + lastStep);
    setConfirmOpen(false);
  };  
  
  const handleDet = event => {
    setDetPreise(parseInt(event.target.value));
  }
  const keyup = (j,i) => (event) => {
    var val = event.target.value;
    if (val < -80) val = -80;
    setChange({...change, [event.target.name]: parseFloat(val)});
    var s = event.target.name.split('.');
    var pks = data.pks;
    pks[s[1]].mod[s[3]] = parseInt(val);
    setData({...data, pks: pks});
  };
  const showSettings = (open) => () => {
    setSettingsOpen(open);
  };  
  const Saved = (settings) => {
    setSettingsOpen(false);
    setData({...data, settings:settings});
    setWeek(week-20000);
  };  

  const Save = () => {
    dataProvider.update('calYield', {id:week, data: data })
      .then(res  => {
        setData(res.data);
        setChange({});
      })
  };  
  
  const PriceGroup = (props) => {
  const [popup, setPopup] = useState(false);
    const {el} = props;
    
    const pgClick = (e) => {
      setPopup(true);
    }
    const pgClose = (e) => {
      e.stopPropagation();
      setPopup(false);
    }
    const pgClickSelect = (id) => (e) => {
      dataProvider.update('calYield', { id:week, data: {
        aktion:'ChangePG',
        date: el.date,
        pg: id
      }})
        .then(res  => {
          ApplyChanges(res.data.pks);
          setData(res.data);
        })
    }
    
    return (
      <div style={{position:"relative",height:"1.5em",marginBottom:"3px",backgroundColor:el.bg,color:el.text, cursor:'pointer'}} onClick={pgClick}>
        <div style={{position:"absolute",whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width:"100%"}}>{el.preisgr}</div>
      {popup &&
        <div style={{position:"absolute",top: "1em", overflow: "auto", width:"20em", height: '20em', background:'#FFF', padding:'1em',zIndex: 10, border: '1px solid', borderRadius: '5px'}}>
          <div style={{textAlign:'right',color:'#000'}}><CloseIcon onClick={pgClose} style={{cursor:'pointer'}} /></div>
        {data.pgs.map((pg)=> (
           <div style={{background:pg.bg, color:pg.text, padding:'0.5em', cursor:'pointer'}} onClick={pgClickSelect(pg.id)}>{pg.bezeichnung}</div>
        ))}        
        </div>
        
      }  
      </div>
    )
  }
  
  const currency = getInfoValue('currency');
  const decimal = getInfoValue('decimal'); 
  var lastMonth='';    
  return (         
    <Paper style={{scroll:'auto'}} >
      <CardContent>
      <div style={{display:'flex', justifyContent: 'space-between'}}>
        <div>
          <Button variant="contained" color="primary" value="-" onClick={Save}><ContentSave/>&nbsp;{translate('bw.cal.yield.save')}</Button>
          <Button variant="contained" color="primary" value="-" onClick={handleButton(-1)} style={{marginLeft:'2em'}}> &lt;&lt; </Button>
          <Button variant="contained" color="primary" value="+" onClick={handleButton(1)} style={{marginLeft:'1em'}}> &gt;&gt; </Button>
        </div>
        <FormControl component="fieldset">
          <FormLabel component="legend">{translate('bw.cal.yield.priceDisplay')}</FormLabel>
          <RadioGroup aria-label="position" name="position" value={detPreise} onChange={handleDet} row>
            <FormControlLabel
              value={0}
              control={<Radio color="primary" />}
              label={translate('bw.cal.yield.def')}
            />
            <FormControlLabel
              value={1}
              control={<Radio color="primary" />}
              label={translate('bw.cal.yield.det')}
            />
            <FormControlLabel
              value={2}
              control={<Radio color="primary" />}
              label={translate('bw.cal.yield.avg')}
            />
          </RadioGroup>
        </FormControl>
        <div>
          <Button variant="contained" color="primary" value="-" onClick={showSettings(true)}><SettingsIcon/></Button>
        </div>
      </div>
  {data.dates && data.dates.length?      
      <Table size="small" className="yieldtab">
        <TableHead>
          <TableRow style={{height:'auto'}}>
            <TableCell colSpan={2} />
            {data.dates.map((el, i) => {
              var d = new Date(el.date);
              var formatter = new Intl.DateTimeFormat('en-us', { weekday: 'narrow'});
              var bold = formatter.formatToParts(d);
              formatter = new Intl.DateTimeFormat('en-us', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric', });
              d = formatter.formatToParts(d);
              bold = bold[0].value==='S';
              var first = d[2].value!==lastMonth;
              lastMonth = d[2].value;
              return (
                <TableCell key={i} style={{fontSize:"0.8125rem"}}>
                  {first?d[2].value+' '+d[6].value:''}<br/>
                  {bold?<b style={{color:'#000'}}>{d[0].value}</b>:d[0].value}<br/>
                  <b>{d[4].value}</b><br/>
                </TableCell>        
              )}
            )}
          </TableRow>
          <TableRow style={{height:'auto'}}>
            <TableCell colSpan={2} />
            {data.dates.map((el, i) => {
              return (
                <TableCell key={i} style={{fontSize:"0.8125rem"}}>
                  <PriceGroup el={el} />
                </TableCell>        
              )}
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.pks.map((pk, j) => (
            <React.Fragment  key={j}>                      
            <TableRow style={{height:'auto'}}>
              <TableCell rowSpan={detPreise===1?pk.preis.length+2:(detPreise===2?4:3)} style={{borderTop:'2px solid #000'}}>{pk.bez}</TableCell>        
              <TableCell style={{borderTop:'2px solid #000'}}>{translate('bw.cal.yield.occ')}</TableCell>        
              {pk.bel.map((el, i) => (
                <TableCell key={i} style={{borderRight:'1px solid #FFF', textAlign:'right', backgroundColor:'#'+data.settings.colors[pk.lvl[i]], borderTop:'2px solid #000'}}>
                  {el}%
                </TableCell>        
              ))}
            </TableRow>
            <TableRow style={{height:'30px'}}>
              <TableCell>Modifyer</TableCell>        
              {pk.mod.map((el, i) => { 
                const key="pks."+j+".mod."+i;
                const changed = typeof change[key]!== 'undefined';
                const val = changed?change[key]:data.pks[j].mod[i];
                return (
                <TableCell key={i} style={{margin:0, textAlign:'right', whiteSpace: 'nowrap'}}>
                  <input name={key} value={data.pks[j].mod[i]} component="input" type="number" style={{width:'3em', margin:0, textAlign:'right', color:val<0?'#F00':(val>0?'#000':'#CCC')
                  , borderColor: changed?'#f00':'#ccc'
                  }} onChange={keyup()} />%
                </TableCell>        
                )}
              )}
            </TableRow>
            {pk.preis.map((preis, k) => { 
                  if (k>0 && detPreise!==1) {
                    return null;
                  }
                  return ( 
                    <TableRow style={{height:'auto'}} key={k}>
                      <TableCell>{preis.bel>0?'Privat '+preis.bel:'Dorm'} [{currency}]</TableCell>        
                      {preis.p.map((el, i) => (
                        <TableCell key={i} style={{margin:0, textAlign:'right'}}>
                          {el<0
                          ?<span>--</span>
                          :<span style={{fontSize:'0.6rem'}}>{el.toFixed(decimal)}<br/></span>}
                          {el<0?null:(el+(change["pks."+j+".mod."+i]?change["pks."+j+".mod."+i]:data.pks[j].mod[i])*el/100).toFixed(decimal)}
                        </TableCell>        
                      ))}
                    </TableRow>
                  )
                }
            )}
            {detPreise===2? 
                <TableRow style={{height:'auto'}}>
                  <TableCell>Average [{currency}]</TableCell>        
                  {pk.belpreis.map((el, i) => (
                    <TableCell key={i} style={{margin:0, textAlign:'right'}}>
                      {el<0.01
                      ?<span>--</span>
                      :<span>{el.toFixed(decimal)}<br/></span>}
                    </TableCell>        
                  ))}
                </TableRow>
            :null}
            </React.Fragment>                      
            )
          )}
        </TableBody>
      </Table>
    :null}  
      <Settings isOpen={settingsOpen} settings={data.settings} show={showSettings} saved={Saved} {...props} />
      <Confirm isOpen={confirmOpen} title="bw.cal.yield.confirmTitel" content="bw.cal.yield.confirmText" onConfirm={handleConfirm} onClose={handleDialogClose}
            />
      </CardContent>
    </Paper>         
  )
}
export default Yield;