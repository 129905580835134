import React from 'react';
import { AppBar, UserMenu, Logout } from 'react-admin';
import IconButton from '@mui/material/IconButton';

import isTest, { testsystemId } from './isTest';
import {MenuConext} from './MenuContext';
import { getGlobalData } from './globalData';
import MainMenuItem from './MainMenuItem';
import SwitchHostel from './SwitchHostel';
import SwitchLang from './SwitchLang';
import GuiVersion from '../version';
import ServerMessage from './ServerMessage';

import HomeIcon from '@mui/icons-material/Home';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import ListAlt from '@mui/icons-material/ListAlt';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Language from '@mui/icons-material/Language';
import SettingsIcon from '@mui/icons-material/Settings';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const MyUserMenu = props => (
    <UserMenu {...props}>
        <SwitchLang />
        <Logout />
    </UserMenu>
);

const MyAppBar = props => {
  const GoTo = (route, mainpos, updateMain) => ev => {
    updateMain(mainpos);
  }
  var testcolor = "#CCFF55"; // giftgelb
  if (testsystemId=='dev1') {
    if (window.location.hostname == 'localhost') testcolor = "#D02628"; // rot, original
    else testcolor = "#FF9800";   // orange
  }
  if (testsystemId=='dev2') testcolor = "#55CCFF";   // hellblau
  if (testsystemId=='dev3') testcolor = "#CC55FF";   // violette
  if (testsystemId=='dev4') testcolor = "#55FFCC";  // hellgrün
  if (testsystemId=='dev5') testcolor = "#FF55CC";   // pink
  
  return (
    <AppBar {...props} color="primary" style={isTest?{backgroundColor:testcolor}:{}} userMenu={<MyUserMenu />}>
      <MenuConext.Consumer>
        {({activeMain, updateMain}) => {

          const info = getGlobalData('info');
          const icons = {home:<HomeIcon />, kalender:<CalendarIcon />, report:<ListAlt />, ifList:<Language />, setHostel:<SettingsIcon />, glob:<FullscreenIcon />}
          const menu = getGlobalData('menu');
          var mainpos = 0;
          var actmenu = 0;
          var lastmenu = localStorage.getItem('lastmenu');
          if (lastmenu !== null) {
            if (activeMain===-1) {
              actmenu = parseInt(lastmenu);
            }
            else {
              actmenu = activeMain;
            }
          }
          var mainButtons = [];
          for(var k in menu) {
            var m = menu[k];
            if (m.id_parent===0) {
              var route = m.route;
              var key = m.route;
              if (!key || !icons[key]) {
                key = 'home';
              }
              if (route==='setHostel') {
                route += '/'+info.id_hostel;
              }
              mainButtons.push(<MainMenuItem key={key} route={route} onClick={updateMain} pos={mainpos} label={m.bez} icon={icons[key]} aktiv={actmenu===mainpos} />);
              mainpos++;
            }
          }
          mainButtons.push(<span key="space" style={{flex: 1}} />);
          mainButtons.push(<IconButton key="contact" onClick={GoTo("/contact", 0, updateMain)} color="inherit"><MailOutlineIcon /></IconButton>);
          if (info && info.canSwitch) {
            mainButtons.push(<SwitchHostel key="switch" />);
          }
          if (info && info.servermsg && info.servermsg.msg) {
            mainButtons.push( <ServerMessage msgdata={info.servermsg} /> );
          }
          mainButtons.push(
            <div style={{position:'absolute',right:'1em',top:'55px',color:'#000',backgroundColor:'white'}} key="info">
              <a href="http://docs.dormproject.ch/de-konfiguration.html?benutzername=dphostel&passwort=sqs8MBX5" target="_blank" rel="noopener noreferrer">
                Documentation
              </a>
              {info && (info.gruppe<4 || info.back)?' '+info.username+'@'+info.hostelname+'['+
                info.id_hostel+'] / '+ GuiVersion+'->'+info.apiversion+'@'+info.host+'->'+info.dbname:' '}
            </div>
          );
          return mainButtons;
        }}
      </MenuConext.Consumer>

    </AppBar>
  )     
}
export default MyAppBar;
