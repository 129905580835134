import React, { useState } from 'react';
import { isHauptadmin } from '../../lib/globalData';
import { useTranslate, useRecordContext, useCreateContext, useChoicesContext, RecordContextProvider, 
        List, Create, Edit, Datagrid, DatagridBody, SimpleForm, FormDataConsumer,
        ReferenceInput, AutocompleteInput, AutocompleteArrayInput, ReferenceArrayInput,
        BooleanInput, NumberInput, ArrayInput, SelectArrayInput, SelectInput, TextInput,
        EditButton, DeleteButton, BulkDeleteButton, Button, ExportButton, SaveButton,
        useRefresh, useNotify, TopToolbar,
        TextField, ReferenceField, Toolbar, regex } from 'react-admin';
import { useFormContext, useController } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { parse } from 'query-string';
import ContentAdd from '@mui/icons-material/Add';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import SButton from '@mui/material/Button';
import HtmlField from '../../fields/HtmlField';
import { ConditionalNumberInput } from '../../fields/ConditionalInput';
import ConditionalTextField from '../../fields/ConditionalTextField';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import dataProvider from '../../lib/dataProvider';
import OportalMultiedit from '../../lib/cal/OportalMultiedit';
import OportalMulticopy from '../../lib/cal/OportalMulticopy';
import FormatTimeArrival from '../../lib/FormatTimeArrival';

import DeleteIcon from 'mdi-react/DeleteIcon';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import Switch from '@mui/material/Switch';

const PostBulkActionButtons = props => (
    <React.Fragment>
        <OportalMulticopy {...props} />
        <OportalMultiedit {...props} />
        {/* default bulk delete action */}
        <BulkDeleteButton {...props} mutationMode="pessimistic"/>
    </React.Fragment>
);

const onlinechoices=[
  { id:  0, name: 'glob.onlinechoices.not_online' },
  { id:  1, name: 'glob.onlinechoices.cons_book_woman' },
  { id:  2, name: 'glob.onlinechoices.cons_book_man' },
  { id:  3, name: 'glob.onlinechoices.cons_book_woman_man' },
  { id:  4, name: 'glob.onlinechoices.cons_book_mixed' },
  { id:  5, name: 'glob.onlinechoices.cons_book_woman_mixed' },
  { id:  6, name: 'glob.onlinechoices.cons_book_man_mixed' },
  { id:  7, name: 'glob.onlinechoices.cons_book_all' },
  { id:  8, name: 'glob.onlinechoices.ign_book_woman' },
  { id:  9, name: 'glob.onlinechoices.ign_book_man' },
  { id: 10, name: 'glob.onlinechoices.ign_book_mixed' },
];

const days=[
  [ 'glob.day.short.mon' ],
  [ 'glob.day.short.tue' ],
  [ 'glob.day.short.wed' ],
  [ 'glob.day.short.thu' ],
  [ 'glob.day.short.fri' ],
  [ 'glob.day.short.sat' ],
  [ 'glob.day.short.sun' ],
];

const DaysString =({ source, ...props }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  if(!record || !record[source]) return null;
  const entries = record[source];
  var s = '';
  for(var i=0;i<entries.length;i++) {
    s += ', '+translate(days[entries[i]-1]);
  }
  return <span>{s.substr(2)}</span>;
}
DaysString.defaultProps = { addLabel: true };

const TextDisplay = ({ disabled, ...props}) => {
  const record = useRecordContext();
  const { allChoices } = useChoicesContext();
  var el = allChoices.find(x => x.id === record.id_preiskat)
  if(!el) return null;
  return (
    <span style={{ textDecoration:disabled?'line-through':'none' }}>{el[props.dispSource]}</span>
  )
}
/*
const useBoolStyles = makeStyles(theme => ({
  block: {
    display: 'inline-block',
    marginTop: '1em',
  },
  short: {
    width: '185px !important',    
  },
  short2: {
    width: '155px !important',    
  }
}));
*/
const BoolKontingentInput = ({ save, saving, undoable, ...props }) => {
  const { getValues, setValue, watch } = useFormContext();
  watch(props.source);
  
  const disab = getValues(props.source)<1;
  return (
    <div style={{ padding: "0 1em", border: "1px solid #ccc",  margin: "0.5em 0.5em 0.3em 0", float:props.float?'left':'none'}}>
      <Switch checked={!disab} onChange={e=> {setValue(props.source,e.target.checked?999:-1, { shouldDirty: true }) }} />
      <NumberInput {...props} disabled={disab} />
    </div>
  )
}

const BoolNumberInput = ({ save, saving, undoable, ...props }) => {
  const { getValues, setValue, watch } = useFormContext();
  const record = useRecordContext();
  const translate = useTranslate();

  watch(props.source);
  if(props.source2) {
    watch(props.source2);
  }

  const changeInput = (val, val2) => {
    setValue(props.source, val, { shouldDirty: true });
    if(props.source2) {
      setValue(props.source2, val2, { shouldDirty: true });
    }
  }
  if(!props || !record) return null;
  if(props.condition && !getValues[props.condition]) return null;
  const disab = getValues(props.source)<0;

  var sub1 = FormatTimeArrival(getValues(props.source), props.unit, translate);
  var sub2 = FormatTimeArrival(getValues(props.source2), props.unit, translate);
  return (
    <div style={{ padding: "0 1em", border: "1px solid #ccc",  margin: "0.5em 0.5em 0.3em 0", float:props.float?'left':'none', position:'relative'}}>
      <Switch checked={!disab} onChange={e=> {changeInput(e.target.checked?props.def:-1, e.target.checked?props.def2:-1)}} />
      <NumberInput {...props} disabled={disab} />
      {sub1 && <div style={{position:'absolute', bottom:'0.5em', left:'90px', fontSize: '0.75rem'}}>{sub1}</div>}
    {props.source2 && 
      <> 
        <NumberInput {...props} source={props.source2} label={props.label2} disabled={disab} />
        {sub2 && <div style={{position:'absolute', bottom:'0.5em', left:'308px', fontSize: '0.75rem'}}>{sub2}</div>}
      </>
    }
    </div>
  )
}

/*
const useSelectStyles = makeStyles(theme => ({
  padding: {
    '& > div:nth-child(1) > div': {paddingTop: '7px'},
    '& > div:nth-child(1) > input': {paddingTop: '7px'},
  },
  flex: {
     '& .MuiFormControl-root': { marginRight: 32, width: '219px' },
  },
}));
  */
const validateSperrBelegung = regex(/^[0-9mfx]+(,[0-9mfx]+)*$|^$/, 'bw.cal.oportal.error.validateSperrBelegung');
const validateInputs = (values) => {
  const errors = {};
  if(values.firstres<0 ^ values.lastnewres<0){
    errors.firstres = 'bw.cal.oportal.error.validateFirstLastres';
    errors.lastnewres = 'bw.cal.oportal.error.validateFirstLastres';
  }
  if(values.lastminstart<0 ^ values.lastminend<0){
    errors.lastminstart = 'bw.cal.oportal.error.validateLastminStartEnd';
    errors.lastminend = 'bw.cal.oportal.error.validateLastminStartEnd';
  }
  return errors;
}

const EForm = ({ permissions, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const [checkCnt, setCheckCnt] = useState({});
  const [checks, setChecks] = useState(false);
  const classes = {} //useSelectStyles();
  const day_choices = days.map((v, i)=>{return{id:i+1,name:v}});


  const SimpleNumInput = ({name, disabled, mutationMode, helperText, ...props}) => {
    const inp = useController({ name, defaultValue: '' });
    return (
      <input {...inp.field} type="number" style={{border: 'none', padding:'5px', width:'100px',textAlign:'right', backgroundColor:disabled?'#FFF':'#EEE' }} />
    )        
  }                                                                         
  const CopyButton = ({title, i, key, ...props}) => {
    const { getValues, setValue } = useFormContext();
    const CopyToAll = (rec, i) => (ev) => {
      const field = rec.prt?'.typ_agent':'.sperr_belegung';
      const rate_value = getValues('tkontingent_x_zimmer.'+i+field);
      const check_value = getValues('tkontingent_x_zimmer.'+i+'.addsub');
      var checksvalues = checks;
      for(var rti=0; rti<rec.tkontingent_x_zimmer.length; rti++){
        setValue('tkontingent_x_zimmer.'+rti+field, rate_value, { shouldDirty: true });
        setValue('tkontingent_x_zimmer.'+rti+'.addsub', check_value, { shouldDirty: true });
        checksvalues[rti] = check_value;
      }
      setChecks(checksvalues);
      setCheckCnt(checkCnt+1);
      return true;
    }
    if(!title) return null;
    return (
      <SButton variant="contained" color="primary" target={key} onClick={CopyToAll(record, i)} style={{marginTop:'1em',width:'130px'}}>{translate(title)}</SButton>
    )
  }
  
  const SimpleCheckbox = ({name, i, ...props}) => {
    const { getValues, setValue } = useFormContext();
    const CheckClick = (i) => (ev) => {
      checks[i] = !checks[i];
      setChecks(checks); 
      setValue(name, checks[i], { shouldDirty: true });
      if (checks[i] && i>0) {
        var rate_value = '';
        if (getValues('tkontingent_x_zimmer.'+(i-1)+'.typ_agent')) {
          rate_value = getValues('tkontingent_x_zimmer.'+(i-1)+'.typ_agent');
          if (rate_value) {
            setValue('tkontingent_x_zimmer.'+i+'.typ_agent', rate_value, { shouldDirty: true });
          }
        }
        else {
          rate_value = getValues('tkontingent_x_zimmer.'+(i-1)+'.sperr_belegung');
          if (rate_value) {
            setValue('tkontingent_x_zimmer.'+i+'.sperr_belegung', rate_value, { shouldDirty: true });
          }
        }
      }
      
      setCheckCnt(checkCnt+1) 
    }
    return (
      <input type="checkbox" checked={checks[i]} onChange={CheckClick(i)} 
        style={{border: 'none', padding:'5px', width:'20px',textAlign:'right' }} 
      />
    )
  }
  const Cell = ({ type, ...props}) => {
    const {classes, saving, undoable, save, label, helperText, validate, ...rest} = props;
    switch(type) {
      case 'value': return (<td style={{padding: '5px 1em', borderBottom:'1px solid #ccc', textDecoration:props.disabled?'line-through':'none'}}>{props.value}</td>)
      case 'num': return (<td style={{padding: '5px 1em', borderBottom:'1px solid #ccc'}} {...rest} ><SimpleNumInput {...props} /></td>)
      case 'check': return (<td style={{padding: '5px 1em', borderBottom:'1px solid #ccc'}} {...rest} ><SimpleCheckbox {...props} /></td>)
      case 'text': return (<td style={{padding: '0px 1em', borderBottom:'1px solid #ccc'}} {...rest} ><TextInput {...props} /></td>)
      case 'component': return (<td style={{padding: '0px 1em', borderBottom:'1px solid #ccc'}} {...rest} ></td>)
      case 'reference': return (<td style={{padding: '0px 1em', borderBottom:'1px solid #ccc'}} {...rest} ><ReferenceInput {...props} /></td>)
      case 'copybutton': return (<td style={{padding: '0px 0.5em', borderBottom:'1px solid #ccc'}} {...rest} ><CopyButton xy="z" {...props} /></td>)
      default: return null;
    }
  }

  if (checks===false && record.tkontingent_x_zimmer_anz) {
    var chk = {};
    for(var i=0; i<record.tkontingent_x_zimmer_anz; i++) {
      chk[i] = record.tkontingent_x_zimmer[i].addsub;
    }
    setChecks(chk);
  }
  var rows = [];
  for(i=0; i<record.tkontingent_x_zimmer_anz; i++) {
    const disabled = !checks[i];
    rows.push(
      <tr key={i}>
        <Cell type="check" name={"tkontingent_x_zimmer."+i+".addsub"} i={i} />
        <Cell type="reference" source="id_preiskat" reference="setRoomcategory" {...props}><TextDisplay dispSource="bezeichnung" disabled={disabled} /></Cell>
        <Cell type="value" value={record.tkontingent_x_zimmer[i].bezeichnung} disabled={disabled}/>
        <Cell type="value" value={translate(onlinechoices[record.tkontingent_x_zimmer[i].online].name)} disabled={disabled} />
        { record.prt ?
          (
            <Cell type="reference" source={"tkontingent_x_zimmer."+i+".typ_agent"} reference="calPortalroom" filter={{ id_agent: record.id_agent }} label="" disabled={disabled} className={classes.padding} >
              <SelectInput optionText="options" label="" helperText="" />
            </Cell>
          )
          :
          (<Cell type="component" >
            <TextInput source={"tkontingent_x_zimmer."+i+".sperr_belegung"} label={false} helperText={false} disabled={disabled} validate={validateSperrBelegung} className={classes.padding} />
          </Cell>)
        }
        <Cell type="copybutton" title={'bw.cal.rate.copy'} key={i+"c6"} i={i} {...props} />

      </tr>
    )
  }

  var filters = { id_online_gr: record.id_online_gr };
  if (window.globalData.filter && window.globalData.filter.onlineAgent) {
    filters.id_agent = window.globalData.filter.onlineAgent;
  }
  if (window.globalData.filter && window.globalData.filter.onlinePreiskat) {
    filters.id_preiskat = window.globalData.filter.onlinePreiskat;
  }
  
  return (
    <SimpleForm validate={validateInputs} toolbar={<CloseToolbar redir={"/calOportal?filter="+JSON.stringify(filters)}/>}{...props} >
      <div style={{ display: 'flex', flexWrap: 'wrap', width: 'auto' }} className={classes.flex} >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <ReferenceInput source="id_online_gr" reference="calOnline" disabled >
            <SelectInput optionText="bezeichnung" label='bw.cal.oportal.id_online_gr' />
          </ReferenceInput>
          <ReferenceInput source="id_agent" reference="calPortal" disabled >
            <SelectInput optionText="firma" label='bw.cal.oportal.firma' />
          </ReferenceInput>
          <ReferenceInput source="id_preiskat" reference="setRoomcategory" disabled >
            <SelectInput optionText="bezeichnung" label='bw.cal.oportal.id_preiskat' />
          </ReferenceInput>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', flexBasis: '100%' }}>
          <BoolKontingentInput {...props} source="kontingent" label='bw.cal.oportal.kontingent' />
          <NumberInput source="freihalten" label='bw.cal.oportal.freihalten' />
          <ConditionalNumberInput dispSource="min_nacht" dispLabel='bw.cal.oportal.min_nacht' condition="min_nacht_enabled" {...props} />
          <ConditionalNumberInput dispSource="max_nacht" dispLabel='bw.cal.oportal.max_nacht' condition="max_nacht_enabled" {...props} />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', flexBasis: '100%' }}>
          <FormDataConsumer>
          {({ formData, ...rest }) => formData.noarrivalwd_enabled &&
            <ArrayInput source="noarrivalwd" label="" fullWidth={false} style={{ width: '500px', marginRight: 0 }} >
              <SelectArrayInput source='id' label='bw.cal.oportal.noarrivalwd' style={{ width: '468px' }} choices={day_choices} />
            </ArrayInput>
          }
          </FormDataConsumer>
          <FormDataConsumer>
          {({ formData, ...rest }) => formData.nodeparturewd_enabled &&
            <ArrayInput source="nodeparturewd" label="" fullWidth={false} style={{ width: '500px' }} >
              <SelectArrayInput source='id' label='bw.cal.oportal.nodeparturewd' style={{ width: '468px' }} choices={day_choices} />
            </ArrayInput>
          }
          </FormDataConsumer>
        </div>
        <FormDataConsumer>
        {({ formData, ...rest }) => formData.pcp &&
          <div style={{ display: 'flex', flexWrap: 'wrap', flexBasis: '100%' }}>
            <div>
              <div style={{ fontSize: '0.75rem' }} dangerouslySetInnerHTML={{ __html: translate('bw.cal.oportal.MBookingInfo') }} />
              <BoolNumberInput {...props} float={true} unit="d"
                 source="firstres" label='bw.cal.oportal.firstres' def={270}
                 source2="lastnewres" label2='bw.cal.oportal.lastnewres' def2={0.75} />
              <BoolNumberInput {...props} float={true} 
                 source="lastchange" label='bw.cal.oportal.lastchange' condition="lastchange_enabled"/>
            </div>
          </div>
        }
        </FormDataConsumer>
        <FormDataConsumer>
        {({ formData, ...rest }) => formData.lastminute_enabled &&
          <div style={{ display: 'flex', flexWrap: 'wrap', flexBasis: '100%' }}>
            <div >
              <div style={{ fontSize: '0.75rem' }} dangerouslySetInnerHTML={{ __html: translate('bw.cal.oportal.SBookingInfo') }} />
              <BoolNumberInput {...props} 
                 source="lastminstart" label='bw.cal.oportal.lastminstart' def={12}
                 source2="lastminend" label2='bw.cal.oportal.lastminend' def2={3} />
            </div>
          </div>
        }
        </FormDataConsumer>
      </div>
      <Table style={{width:'auto', borderCollapse:'collapse', padding:0}} >
        <tbody>
          <tr align="left">
            <th></th>
            <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('bw.cal.oportal.id_preiskat')}</th>
            <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('bw.cal.oportal.room')}</th>
            <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('bw.cal.oportal.online')}</th>
            <FormDataConsumer>
            {({ formData, ...rest }) => formData.prt ?
              <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('bw.cal.oportal.bed')}</th>
              :
              <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('bw.cal.oportal.sperr_belegung')}</th>
            }
            </FormDataConsumer>
          </tr>
          {rows}
        </tbody>
      </Table>
      <FormDataConsumer>
      {({ formData, ...rest }) => formData.prt ? null :
        <div style={{padding: '2em 1em', fontSize: 'small' }} >
        {translate('bw.cal.oportal.sperr_belegung_info')}
        </div>
      }
      </FormDataConsumer>
    </SimpleForm>
  )
};

const CForm = ({ hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const record = useCreateContext();
  const params = window.location.hash.split('?');
  const { id_online_gr: id_online_gr_string } = parse(params[1]);
  const id_online_gr = id_online_gr_string ? parseInt(id_online_gr_string, 10) : '';
  record.id_online_gr = id_online_gr;
  
  return (
    <SimpleForm toolbar={ <CloseToolbar redir={"/calOportal?filter="+JSON.stringify({ id_online_gr: id_online_gr })} />} {...props} >
      <ReferenceInput source="id_online_gr" reference="calOnline" >
        <SelectInput optionText="bezeichnung" label='bw.cal.oportal.id_online_gr' />
      </ReferenceInput>
      <ReferenceArrayInput source="id_agent_add" reference="calPortal" sort={{ field: 'firma', order: 'ASC'}} filter={{AddAll:true}} >
        <AutocompleteArrayInput optionText="firma" label='bw.cal.oportal.id_agent' />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="id_preiskat_add" reference="setRoomcategory" sort={{ field: 'pos', order: 'ASC'}} filter={{AddAll:true}}>
        <AutocompleteArrayInput optionText="bezeichnung" label='bw.cal.oportal.id_preiskat' />
      </ReferenceArrayInput>
    </SimpleForm>
  )
};

const listFilter = [
  <ReferenceInput source="id_online_gr" reference="calOnline" allowEmpty={false} alwaysOn >
    <SelectInput optionText="bezeichnung" label='bw.cal.oportal.id_online_gr' />
  </ReferenceInput>,
  <ReferenceInput source="id_agent" reference="calPortal" sort={{ field: 'firma', order: 'ASC'}} alwaysOn resettable >
    <AutocompleteInput optionText="firma" label='bw.cal.oportal.id_agent' emptyText="bw.cal.oportal.emptyPortal" />
  </ReferenceInput>,
  <ReferenceInput source="id_preiskat" reference="setRoomcategory" sort={{ field: 'pos', order: 'ASC'}} alwaysOn >
    <AutocompleteInput optionText="bezeichnung" label='bw.cal.oportal.id_preiskat' emptyText="bw.cal.oportal.emptyRoomcat" resettable />
  </ReferenceInput>,
  <BooleanInput source="details" alwaysOn />
]

const RefCreateButton = () => {
  const record = useRecordContext();
  return (
    <Button component={Link}
      to={`/calOportal/create?id_online_gr=${record?record.id_online_gr:0}`}
      label='ra.action.create' >
      <ContentAdd />
    </Button>
  );
}

const ListActions = (props) => (
  <TopToolbar>
    <RefCreateButton />
    {props.exporter !== false && (
      <ExportButton />
    )}
  </TopToolbar>
)
const NumberDisplay = (props) => {
  const record = useRecordContext();
  if(record && record[props.source]<0) return null;
  return (
    <TextField {...props} />
  )
};
/*
const useListStyles = makeStyles(theme => ({
  padding: {
    '& th, & td': {paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1)},
  },
}));
*/

const MyDatagridRow = ({ record, id, onToggleItem, children, selected, selectable, ...props }) => {
  const translate = useTranslate();
  const missconfig_count = record && record.missconfig && record.missconfig_zimmer ? record.missconfig_zimmer.split(',') : [];
  return (
    <RecordContextProvider value={record}>
      <TableRow>
        {/* first column: selection checkbox */}
        <TableCell sx={{padding:"0 12px 0 7px"}}>
          <Checkbox
            checked={selected}
            onClick={event => onToggleItem(id, event)}
          />
        </TableCell>
        {/* data columns based on children */}
        {React.Children.map(children, field => {
          if (field === null) return null;
          return (
          <TableCell key={`${id}-${field.props.source}`}>
            {field}
          </TableCell>
        )})}
      </TableRow>
    { record && record.missconfig &&
      <TableRow key={props.id+1} style={{backgroundColor: 'mistyrose'}}>
        <TableCell colSpan={children.length-3+isHauptadmin()}>
          <span>
            <b style={{color:'red'}}>{translate('bw.cal.oportal.error.missConfig')}</b>
            {translate('bw.cal.oportal.error.missConfig2', missconfig_count.length)}
            <b> {record.missconfig_zimmer}</b>
          </span>
        </TableCell>
        <TableCell colSpan={3} align='right'>
          <CleanupButton {...props} />
        </TableCell>
      </TableRow>
    }
    </RecordContextProvider>
  )
}

const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;

const DialogToolbar = props => (
  <Toolbar {...props} >
    <SaveButton label="glob.confirm" redirect="show" submitOnEnter={true} icon={<DeleteIcon />} alwaysEnable />
  </Toolbar>
);

const CleanupButton = (props) => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [showDialog, setShowDialog] = useState(false);
  const translate = useTranslate();
  const missconfig_count = record.missconfig && record.missconfig_zimmer ? record.missconfig_zimmer.split(',') : [];
  const handleClick = () => {
    setShowDialog(true);
  };
  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const doSave = (data) => {
    data.mc_ids = record.missconfig_ids;
    dataProvider.update('calOportal', { id: props.id, data:data })
      .then(({ data }) => {
        setShowDialog(false);
        refresh();
      })
      .catch(error => {
        notify(error.message, 'error');
      })
  };
  return (
    <React.Fragment>
      <Button onClick={handleClick} label="bw.cal.oportal.cleanup">
        <DeleteIcon />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="bw.cal.oportal.cleanup" >
        <DialogTitle>{translate("bw.cal.oportal.cleanup")}</DialogTitle>
        <DialogContent>
          <SimpleForm resource="calOportal" toolbar={<DialogToolbar />} onSubmit={doSave} >
            <React.Fragment>
              <div>{translate('bw.cal.oportal.cleanupInfo', missconfig_count.length)} <b>{record.missconfig_zimmer}</b></div>
            </React.Fragment>
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
};

const ListForm = ({ translate, ...props}) => {
//  <List {...props} title="bw.cal.oportal.title" sort={{ field: 'strasse', order: 'ASC' }} filters={listFilter} filterDefaultValues={{ details: false }} pagination={<NoPagination />} perPage={1000} bulkActionButtons={<PostBulkActionButtons />} actions={<ListActions />} >
  return (
  <List {...props} title="bw.cal.oportal.title" sort={{ field: 'strasse', order: 'ASC' }} filters={listFilter} filterDefaultValues={{ details: false }} pagination={<NoPagination />} perPage={1000} bulkActionButtons={<PostBulkActionButtons />} actions={<ListActions />} >
    <MyDatagrid style={{ width: 'auto' }} >
      <TextField source="strasse" label="bw.cal.oportal.firma"  />
      <ReferenceField source="id_preiskat" reference="setRoomcategory" sortable={false} link={false}>
        <TextField source="bezeichnung" />
      </ReferenceField>
      <ConditionalTextField dispSource="kontingent" label="resources.calOportal.fields.kontingent" condition="kontingent" />
      <ConditionalTextField dispSource="freihalten" label="resources.calOportal.fields.freihalten" condition="freihalten" />
      <NumberDisplay source="min_nachtA" />
      <NumberDisplay source="max_nachtA" />
      <DaysString source="noarrivalwd" />
      <DaysString source="nodeparturewd" />
      <NumberDisplay source="firstres" />
      <NumberDisplay source="lastnewres" />
      <NumberDisplay source="lastminstart" />
      <NumberDisplay source="lastminend" />
      <HtmlField source="zimmer" />
      {isHauptadmin() ?
        <TextField source="id" textAlign="right" /> 
        : null
      }
      <EditButton label="" />
      <DeleteButton label="" />
    </MyDatagrid>
  </List>
  )
}

const oportal = {
  list: ({ ...props }) => (
    <ListForm {...props} />
  ),

  create: ({ ...props }) => (
    <Create {...props} title="bw.cal.oportal.titleCreate" redirect="list">
      <CForm {...props} />
    </Create>
  ),

  edit: ({ ...props }) => (
    <Edit title="bw.cal.oportal.titleEdit" mutationMode="pessimistic" {...props} redirect="/calOportal/">
      <EForm {...props} />
    </Edit>
  )
}
export default oportal;